<template>
  <div>
    <MarkAttendance
      :date.sync="date"
      :user-hash-id.sync="userHashId"
      :sidebar-mark-attendance-active.sync="sidebarMarkAttendanceActive"
    />
    <b-card>
      <b-form>
        <b-row>
          <b-col cols="12" md="5" sm="12" class="mb-md-0 mb-2">
            <label>Employee</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="userHashId"
              :options="employeeListOptions"
              :clearable="false"
              class="w-100"
              :reduce="(val) => val.code"
              placeholder="Select Employee.."
            />
          </b-col>

          <b-col cols="12" md="2" sm="6" class="mb-md-0 mb-2">
            <label>Month</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="month"
              :clearable="false"
              :options="months"
              label="name"
              class="w-100"
              :reduce="(val) => val.number"
              placeholder="Select Months.."
            />
          </b-col>
          <b-col cols="12" md="2" sm="6" class="mb-md-0 mb-2">
            <label>Year</label>
            <b-form-spinbutton
              v-model="year"
              min="2020"
              :max="new Date().getFullYear()"
            />
          </b-col>
          <b-col cols="2" class="mb-2 mb-md-0">
            <b-button
              variant="success"
              class="mt-2 w-100"
              @click="fetch_user_data"
            >
              Filter
            </b-button>
          </b-col>
        </b-row>

        <b-modal
          id="show-swipes"
          hide-footer
          size="lg"
          modal-class="modal-primary"
          scrollable
          title="Swipes"
        >
          <b-list-group v-if="userSwipes && userSwipes.length">
            <b-list-group-item
              class="d-flex align-items-center"
              v-for="swipe in userSwipes"
              :key="swipe.hashid"
            >
              <b>Date :</b> {{ swipe.date | formatDate }}, <b>Time :</b>
              {{ swipe.time | formatTime }}, <b>Swipe type :</b>
              {{ swipe.type }}
              <b-img
                v-if="swipe.user_feeling"
                v-bind="{ width: 40, height: 40, class: 'mr-0 ml-auto' }"
                fluid
                :src="options[swipe.user_feeling]"
                alt="mood"
              />
            </b-list-group-item>
          </b-list-group>
          <b-card-text v-else-if="userSwipes && userSwipes.length == 0">
            <div class="item error text-center my-2 empty-data">
              <b-img :src="require('@/assets/images/empty-data/no-record.svg')" width="200" class="mr-50" style="background-color: transparent" />
              <h4> OH NO!</h4>
              <p class="d-block mb-1">No Records Found!</p>
            </div>
          </b-card-text>
          <b-card-text v-else="userSwipes == null">
            <div class="d-flex align-items-center">
              <strong>Loading...</strong>
              <b-spinner class="ml-auto" variant="primary" />
            </div>
          </b-card-text>
        </b-modal>

        <div>
          <b-overlay
            :show="isBusy"
            spinner-variant="primary"
            style="min-height: 200px"
          >
            <table class="table table-striped mt-2">
              <thead>
                <tr>
                  <th scope="col">DATE</th>
                  <th scope="col">STATUS</th>
                  <th scope="col">CHECK IN</th>
                  <th scope="col">CHECK OUT</th>
                  <th scope="col">OTHERS</th>
                  <th scope="col">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(attendance, index) in attendances" :key="index">
                  <th scope="row">
                    {{ attendance.date | formatDate("DD-MM-YYYY dddd") }}
                  </th>
                  <td>
                    <span
                      v-if="attendance.status == 'Present'"
                      class="badge badge-pill badge-light-success"
                    >
                      {{ attendance.status }}
                    </span>
                    <span
                      v-else-if="attendance.holiday != 'false'"
                      class="badge badge-pill badge-info"
                    >
                      {{ attendance.holiday }}
                    </span>
                    <span
                      v-else-if="attendance.status == 'Regularized'"
                      class="badge badge-pill badge-info"
                    >
                      {{ attendance.status }}
                    </span>
                    <span
                      v-else-if="attendance.status == 'Rest Day'"
                      class="badge badge-pill badge-info"
                    >
                      {{ attendance.status }}
                    </span>
                    <span
                      v-else-if="attendance.status"
                      class="badge badge-pill badge-light-danger"
                    >
                      {{ attendance.status }}
                    </span>
                  </td>
                  <td>{{ attendance.first_in | formatTime("hh:mm A") }}</td>
                  <td>{{ attendance.last_out | formatTime("hh:mm A") }}</td>
                  <td>
                    <span
                      v-if="attendance.regularization == 'Yes'"
                      class="badge badge-pill badge-light-success"
                    >
                      Regularized
                    </span>
                    <span
                      v-if="attendance.onLeave != ''"
                      class="badge badge-pill badge-light-warning"
                    >
                      {{ attendance.onLeave }}
                    </span>
                  </td>
                  <td>
                    <feather-icon
                      @click="markAttendanceSidebar(attendance.date)"
                      v-if="$can('Zircly Admin')"
                      v-b-tooltip.hover
                      v-b-toggle.MarkAttendanceSidebar
                      title="Edit"
                      icon="EditIcon"
                      size="16"
                      class="mr-1 cursor-pointer"
                    />
                    <feather-icon
                      @click="getUserSwipes(attendance.date)"
                      v-b-tooltip.hover
                      v-b-modal.show-swipes
                      title="View Swipes"
                      icon="EyeIcon"
                      size="16"
                      class="cursor-pointer"
                    />
                  </td>
                </tr>
                <tr v-if="attendances.length < 1">
                  <td colspan="6">
                    <span class="text-center"> No Data found ! </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-overlay>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
  BFormSpinbutton,
  BImg,
} from "bootstrap-vue";
import { required, email, numeric } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { onUnmounted } from "@vue/composition-api";
import store from "@/store";
import attendanceMusterStoreModule from "../attendanceMusterStoreModule";
import StarRating from "vue-star-rating";
import formValidation from "@core/comp-functions/forms/form-validation";
import MarkAttendance from "./MarkAttendance";
let months = require("/src/libs/months-detailed.json");
import { bus } from "@/main.js";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupAppend,
    BInputGroup,
    BFormSpinbutton,
    BForm,
    BImg,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    StarRating,
    flatPickr,
    MarkAttendance,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      months: months,
      date: "",
      isBusy: false,
      month: "",
      year: "",
      employeeListOptions: [],
      userSwipes: [],
      attendances: [],
      userHashId: "",
      sidebarMarkAttendanceActive: false,
      options: {
        1: require("@/assets/images/moods/angry.svg"),
        2: require("@/assets/images/moods/thinking.svg"),
        3: require("@/assets/images/moods/neutral.svg"),
        4: require("@/assets/images/moods/shy.svg"),
        5: require("@/assets/images/moods/cute.svg"),
      },
    };
  },
  watch: {
    month(val) {
      this.fetch_user_data();
    },
    year(val) {
      this.fetch_user_data();
    },
    userHashId(val) {
      this.fetch_user_data();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-leaves";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        attendanceMusterStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });
    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  created() {
    bus.$on("fetchUserData", () => {
      console.log("Events : fetchUserData");
      this.fetch_user_data();
    });
    // this.getCustomFields();
    this.fetchEmployeeList();
    this.userHashId = this.$route.params.id;
    this.month = new Date().getMonth() + 1;
    this.year = new Date().getFullYear();
    this.fetch_user_data();
  },
  methods: {
    markAttendanceSidebar(date) {
      this.date = date;
    },
    getUserSwipes(date) {
      const self = this;
      let data = {};
      data.url = "swipes/" + self.userHashId + "/" + date;
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.isBusy = false;
          this.userSwipes = res.data.data;
        })
        .catch((error) => {
          this.isBusy = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Oops! Event Adding Failed",
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    fetchEmployeeList() {
      this.$store
        .dispatch("app/fetchEmployeeList")
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "fetch Employee List failed.",
              icon: "BellIcon",
              variant: "danger",
              text: err.toString(),
            },
          });
        });
    },
    openWindow(link) {
      window.open(link);
    },
    fetch_user_data() {
      const self = this;
      let data = {};
      data.url = "attendance-muster/" + self.userHashId;
      data.params = {
        month: self.month,
        year: self.year,
      };
      this.isBusy = true;
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.isBusy = false;
          this.attendances = res.data.data;
        })
        .catch((err) => {
          this.isBusy = false;
          console.error(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
// Import FilePond styles
@import "~filepond/dist/filepond.min.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
.filepond--credits {
  display: none;
}
span.rating-btn {
  width: 130px;
  margin-left: 5px;
  padding: 2px;
  color: #fff;
  border-radius: 2px;
  font-size: 13px;
  text-align: center;
  font-weight: 700;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  line-height: 25px;
}
.star-belowAverage {
  background: #f5c357;
}
.star-poor {
  background: #bababa;
}
.star-average {
  background: #f3d23e;
}
.star-good {
  background: #c1d759;
}
.star-excellent {
  background: #48964d;
}
</style>
